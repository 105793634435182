import { render, staticRenderFns } from "./FortuneBagGoodsTable.vue?vue&type=template&id=d94bdc9a&scoped=true&"
import script from "./FortuneBagGoodsTable.vue?vue&type=script&lang=js&"
export * from "./FortuneBagGoodsTable.vue?vue&type=script&lang=js&"
import style0 from "./FortuneBagGoodsTable.vue?vue&type=style&index=0&id=d94bdc9a&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d94bdc9a",
  null
  
)

export default component.exports